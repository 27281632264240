<template>
  <div class="pt-10 pb-5 flex flex-col gap-y-5">
    <!-- Input Date -->
    <div id="Date" class="flex flex-row items-start gap-x-2 px-2">
      <img src="/svg/calendar_sm.svg" />
      <div class="flex flex-col">
        <label for="Jour" class="text-gray-600 text-base font-EffraR"
          >Jour</label
        >
        <span
          class="relative text-blackedsidebar text-lg font-EffraR capitalize"
          >{{ _moment()(startAt).format("dddd, DD MMMM") }}
          <date-picker
            :clearable="false"
            format="DD-MM-YYYY"
            v-model="startAt"
            :lang="DatePickerLocal"
            id="Jour"
            class="absolute inset-x-0 opacity-0 w-auto"
        /></span>
      </div>
    </div>

    <!--  Input From & To -->
    <div class="grid gap-4 grid-cols-2 px-2">
      <!-- From time -->
      <div id="Date" class="flex flex-row items-start gap-x-2">
        <img src="/svg/clock_sm.svg" />
        <div class="flex flex-col">
          <label for="Jour" class="text-gray-600 text-base font-EffraR"
            >De</label
          >
          <span
            class="relative text-blackedsidebar text-lg font-EffraR capitalize"
          >
            {{ form.fromHour && form.fromHour.HH ? form.fromHour.HH : "--" }}:{{
              form.fromHour && form.fromHour.mm ? form.fromHour.mm : "--"
            }}

            <time-picker
              placeholder="hh:mm"
              v-model="form.fromHour"
              format="HH:mm"
              :minute-interval="15"
              :hour-range="[[8, 23]]"
              class="absolute inset-x-0 top-0 w-auto"
              input-class="opacity-0"
              hide-clear-button
              hide-disabled-items
            ></time-picker>
          </span>
        </div>
      </div>

      <!-- To Time -->
      <div id="Date" class="flex flex-row items-start gap-x-2">
        <img src="/svg/clock_sm.svg" />
        <div class="flex flex-col">
          <label for="Jour" class="text-gray-600 text-base font-EffraR"
            >à</label
          >
          <span
            class="relative text-blackedsidebar text-lg font-EffraR capitalize"
            >{{ form.toHour && form.toHour.HH ? form.toHour.HH : "--" }}:{{
              form.toHour && form.toHour.mm ? form.toHour.mm : "--"
            }}

            <time-picker
              placeholder="hh:mm"
              v-model="form.toHour"
              format="HH:mm"
              :minute-interval="15"
              :hour-range="[[8, 23]]"
              class="absolute inset-x-0 top-0 w-auto"
              input-class="opacity-0"
              hide-clear-button
              hide-disabled-items
            ></time-picker>
          </span>
        </div>
      </div>
    </div>

    <div class="flex items-center px-2">
      <input
        type="checkbox"
        id="selectAllDay"
        class="
          mr-2
          form-checkbox
          border-2 border-solid border-grayDok-sidebar
          text-2xl
          focus:outline-none
        "
        v-model="reserveDay"
      />
      <label
        for="selectAllDay"
        class="font-EffraR text-base text-blackedsidebar"
      >
        Réserver toute la journée</label
      >
    </div>

    <!-- Note -->
    <div
      class="
        flex flex-col
        px-2
        border-0 border-t border-solid border-gray-300
        pt-3
      "
    >
      <label for="note" class="font-EffraR text-gray-700">Note</label>
      <div class="mt-1">
        <textarea
          id="note"
          class="
            w-full
            py-3
            px-1
            font-EffraR
            text-base text-gray-800
            bg-white
            border-2 border-solid border-grayaaa
            rounded-cu
            hover:border-gray-600
            focus:outline-none
            resize-none
            h-100
          "
          placeholder="Note..."
          v-model="form.note"
        ></textarea>
      </div>
    </div>

    <!-- Button Save -->
    <div class="px-2">
      <button
        @click="onCreate"
        class="
          h-12
          flex
          items-center
          justify-center
          gap-x-2
          bg-dokBlue-ultra
          rounded-cu
          border-0
          w-full
          cursor-pointer
        "
      >
        <img src="/svg/no-vide.svg" />
        <span class="text-lg font-EffraR text-white">Réserver le créneau</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";

// Date Picker
import DatePicker from "vue2-datepicker";
import { fr } from "vue2-datepicker/locale/fr";
import "vue2-datepicker/index.css";

// Time Picker
import TimePicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DatePickerLocal: fr,
      startAt: null,
      reserveDay: null,
    };
  },
  watch: {
    reserveDay: function (newVal, oldVal) {
      if (newVal) {
        this.form.fromHour = {
          HH: "08",
          mm: "00",
        };
        this.form.toHour = {
          HH: "23",
          mm: "00",
        };
      } else {
        this.form.fromHour = {};
        this.form.toHour = {};
      }
    },
  },
  created() {
    this.startAt = this.form.startAt.toDate();
  },
  computed: {
    ...mapGetters({
      slots: "agenda/slots",
    }),
  },
  methods: {
    ...mapActions("agenda", ["CREATE_EVENT", "UPDATE_EVENT"]),
    _moment() {
      return moment;
    },
    async onCreate() {
      if (
        !this.form.fromHour ||
        !this.form.toHour ||
        !this.form.fromHour.HH ||
        !this.form.toHour.HH ||
        !this.form.fromHour.mm ||
        !this.form.toHour.mm
      ) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Merci de choisir une heure de début et de fin.",
          timerAlert: 5000,
        });
      }

      let startAtToMoment = moment(this.startAt).set({
        h: this.form.fromHour.HH,
        m: this.form.fromHour.mm,
      });

      let endAtToMoment = moment(this.startAt).set({
        h: this.form.toHour.HH,
        m: this.form.toHour.mm,
      });

      const allEventInThisDay = this.$store.state.agenda.eventsAgenda.filter(
        (event) => {
          return moment(event.startAt).isSame(startAtToMoment, "day");
        }
      );

      // Check if exsits any events in this period
      let itemsCheck = [];
      allEventInThisDay.forEach((event) => {
        if (
          moment(event.startAt).isSameOrAfter(startAtToMoment) &&
          moment(event.startAt).isSameOrBefore(endAtToMoment)
        ) {
          itemsCheck.push(event._id);
        }
      });

      if (itemsCheck.length) {
        return EventBus.$emit("flashDok", {
          timerAlert: 5000,
          type: "errorr",
          message:
            "Attention! Ce créneau contient déjà un rendez-vous confirmé!",
        });
      }
      // End Check

      const { data, ok } = await this.CREATE_EVENT({
        form: {
          startAt: startAtToMoment,
          endAt: endAtToMoment,
          note: this.form.note,
          type: "RESERVED-SLOT",
        },
      });

      if (ok) {
        this.$performTracking({
          data: {
            id: this.$auth.getUserId(),
            route: "HORAIRE",
            action: "BLOQUE",
            data: {},
          },
        });
        EventBus.$emit("Modal_Has_New_Success_Appointment", data);
      }
    },
  },
  components: {
    DatePicker,
    TimePicker,
  },
};
</script>

<style>
.vue__time-picker {
  @apply w-full;
}
</style>
